:root {
  --solytics-editor-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tiptap:focus-visible,
.tiptap:focus {
  outline: none;
}

.ProseMirror-focused {
  outline: none;
}

.tiptap ul,
ol {
  padding-inline-start: 20px;
}

.tiptap pre {
  background: #000000;
  border-radius: 0.5rem;
  color: #fff;
  font-family: monospace !important;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.tiptap code {
  background-color: #f6f2ff;
  border-radius: 0.4rem;
  color: #000000;
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
  font-family: monospace !important;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap blockquote {
  border-left: 3px solid #0bb7a7;
  margin: 1.5rem 0;
  padding-left: 1rem;
}

.tiptap hr {
  border: none;
  border-top: 1px solid #0bb7a7;
  margin: 2rem 0;
}

.tiptap img {
  width: 100%;
  margin: 0 auto;
}

.tiptap img.ProseMirror-selectednode {
  outline: 3px solid #0bb7a7;
}

.tiptap table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.tiptap td,
th {
  border: 1px solid #e7e4e2;
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

.tiptap td,
th>* {
  margin-bottom: 0;
}

.tiptap th {
  background-color: #f5f4f3;
  font-weight: bold;
  text-align: left;
}

.tiptap .selectedCell::after {
  background: #0bb7a720;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.tiptap .column-resize-handle {
  background-color: #0bb7a7;
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: 0;
  width: 4px;
}

.tiptap .tableWrapper {
  margin: 1.5rem 0;
  overflow-x: auto;
}

.tiptap.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.tiptap table[data-border="hidden"] td {
  border: none;
}

.tiptap table[data-border="hidden"] th {
  border: none;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}