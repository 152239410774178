* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* default color */
  --primary-color: #0BB7A7;

}

html {
  scroll-behavior: smooth !important;
}

*:focus-visible {
  outline: 2px solid var(--primary-color);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*:hover::-wesbkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  opacity: 0.5;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Vertical scrollbar */
*::-webkit-scrollbar-thumb:vertical {
  background-color: var(--primary-color);
  border: 2px solid transparent;
  background-clip: content-box;
}

/* Horizontal scrollbar */
*::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--primary-color);
  border: 2px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:vertical:hover {
  border: 5px solid var(--primary-color);
}

*::-webkit-scrollbar-thumb:horizontal:hover {
  border: 5px solid var(--primary-color);
}

.custom-link:focus-visible {
  outline: 2px solid var(--primary-color);
}

.custom-link:hover {
  cursor: pointer;

}