
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets//fonts/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins",sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.resizer {
  position: absolute;
  height: 100%;
  width: 4px;
  top: 0;
  right: -2px;
  cursor: col-resize;
}

/* On hover or when the user is close to it */
.resizer:hover {
  background-color: #0BB7A7; /* Visible on hover */
  opacity: 1; /* Fully visible */
}

/* Optional: Styling for the header-specific resizer visibility */
.header .resizer {
  height: 100%; /* Adjust height for header if necessary */
  opacity: 0.5; /* More visible in the header area */
}


.gutter-horizontal {
  width: '5px !important';
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}